import WebrtcClient from "../webrtc_client";
import jQuery from "jquery";

const $ = jQuery;

// window.visiwebrtc_log = [];
function Log(a) {
  const str = a.toString();
  // window.visiwebrtc_log.push(str);
}

function VisiWebRTC(options) {
  const that = this;

  that.preferred_string = "___PREFERRED___";
  that.server = options.server;
  that.email = options.email;
  // that.password = options.password;
  that.name = options.name;
  that.api_key = options.api_key || null;
  that.meetingID = options.meetingID;
  that.callback = options.callback || function () {};
  that.log = options.log || function () {};
  that.blur = options.blur || false;
  that.joining_meeting = false;
  that.jwt = options.jwt;
  that.id = options.id;

  const [serviceName, ...[domain]] = that.server.split(/\.(.*)/s);
  that.urls = {
    api: `${serviceName}-api.${domain}`,
    msg: `${serviceName}-msg.${domain}`,
    auth: `${serviceName}-auth.${domain}`,
    ucs: `${serviceName}-ucs.${domain}`,
  };

  that.objectToArray = function (o) {
    const a = [];
    Object.keys(o).forEach((key) => {
      a.push(o[key]);
    });
    return a;
  };

  that.xml2str = function (xmlNode) {
    if (!xmlNode) {
      return "";
    }

    try {
      return new XMLSerializer().serializeToString(xmlNode);
    } catch (e) {
      try {
        return xmlNode.xml;
      } catch (f) {
        Log("error parsing xml");
        Log(xmlNode);
      }
    }
    return "";
  };

  that.textToXML = function (str) {
    return new DOMParser().parseFromString(str, "text/xml");
  };

  that.connectToMeeting = function (options) {
    that.joining_meeting = false;
    options.callback = options.callback || function () {};

    that.videoStreamRemoved = options.videoStreamRemoved || function () {};
    that.videoStreamAdded = options.videoStreamAdded || function () {};

    that.audioStreamRemoved = options.audioStreamRemoved || function () {};
    that.audioStreamAdded = options.audioStreamAdded || function () {};

    // that.toolsNotification = options.toolsNotification || function () {};
    that.meetingID = options.meetingID || that.meetingID;

    // Start the audio notification session
    that.audio_notification_client = new WebrtcClient({
      mjwt: that.mjwt,
      server: that.urls.ucs,
      email: that.email,
      type: "audio",
      meetingID: that.meetingID,
      mode: "none",
      ucg: false,
      log: that.log,
      notification_callback(xml) {
        if (!xml) {
          return;
        }

        xml = that.textToXML(xml);

        that.audioStreamRemoved = options.audioStreamRemoved || (() => {}); // save their callback

        $(xml)
          .find("source[id]")
          .each((i, s) => {
            const email = $(s).find("cname").text();

            if (!email) {
              return;
            }
            if (email === that.email) {
              return;
            }

            const id = $(s).attr("id");
            const name = $(s).find("name").text();
            that.audioStreamAdded(id, email, name);
          });

        $(xml)
          .find("event[name='audio_stream_removed'] source")
          .each((i, s) => {
            const id = $(s).attr("id");
            that.audioStreamRemoved(id);
          });
      },
      error_callback(a, b, c) {
        options.callback(c);
      },
      completed_callback() {
        // Start the video notification session
        that.video_notification_client = new WebrtcClient({
          mjwt: that.mjwt,
          server: that.urls.ucs,
          email: that.email,
          type: "video",
          meetingID: that.meetingID,
          mode: "none",
          ucg: false,
          log: that.log,
          completed_callback() {
            options.callback(
              null,
              that.video_receive_max,
              that.meetingID,
              that.subject,
              that.video_initial_send,
              that.audio_initial_send
            );
          },
          notification_callback(xml) {
            if (!xml) {
              return;
            }

            xml = that.textToXML(xml);

            that.remote_video_streams = that.remote_video_streams || {};

            $(xml)
              .find("source[id]")
              .each((i, s) => {
                const email = $(s).find("cname").text();

                if (!email) {
                  return;
                }
                if (!options.echoLocalVideo && email === that.email) {
                  return;
                }

                const id = $(s).attr("id");
                const name = $(s).find("name").text();
                const codecName = $(s).find("codecName").text() || "";
                const isScreenShare =
                  codecName.includes("Screen") ||
                  codecName.includes("Share") ||
                  codecName.includes("Capture");
                let camera_name = $(s).find("camera-name").text();
                camera_name = camera_name.replace(that.preferred_string, "");

                that.remote_video_streams[id] = {
                  id,
                  email,
                  name,
                  codecName,
                  isScreenShare,
                  camera: camera_name,
                };
                that.videoStreamAdded(
                  id,
                  email,
                  name,
                  camera_name,
                  codecName,
                  isScreenShare
                );
              });

            $(xml)
              .find("event[name='video_stream_removed'] source")
              .each((i, s) => {
                const id = $(s).attr("id");
                that.videoStreamRemoved(id);
                delete that.remote_video_streams[id];
              });
          }, // video_notification_client notification_callback
        }); // video_notification_client init
      }, // audio_notification_client completed_callback
    }); // audio_notification_client init
  };

  that.video_send_clients = {};

  that.enableLocalVideo = function (
    callback,
    bandwidth,
    resolution,
    deviceID,
    facingMode,
    errorCallback = function () {},
    cameraName = "Camera",
    share = false,
    onended = function () {}
  ) {
    const id = deviceID || "default";

    that.video_send_clients[id] = new WebrtcClient({
      mjwt: that.mjwt,
      server: that.urls.ucs,
      email: that.email,
      site: that.name,
      device_name: cameraName,
      type: "video",
      meetingID: that.meetingID,
      mode: "sendonly",
      device_id: deviceID,
      screen: share || deviceID === "share",
      facingMode: facingMode || null,
      bandwidth: bandwidth || undefined,
      resolution: resolution || undefined,
      ucg: false,
      log: that.log,
      onended: onended,
      completed_callback: () => {
        if (callback) {
          const stream = that.video_send_clients[id]?.stream;
          const audio = window.audio_send_client;
          callback(stream, audio);
        }
      },

      error_callback: errorCallback,
      blur: that.blur,
    });
  };

  that.swapFacingMode = function (mode) {
    Object.values(that.video_send_clients)?.[0]?.swapFacingMode(mode);
  };

  that.disableLocalVideo = async function (id, callback = () => {}) {
    if (id) {
      await that.video_send_clients[id].stop();
    } else {
      const promises = Object.keys(that.video_send_clients).map((curID) =>
        that.video_send_clients[curID].stop()
      );

      await Promise.all(promises);
    }
    callback();
  };

  that.enableRemoteVideo = function (id, callback, isScreenShare) {
    if (!that.video_recv_client) {
      if (that.setting_up_video_receive) {
        $(document).one("onVideoRecvClient", () => {
          that.video_recv_client.turnOn(id, (stream) => {
            that.remote_video_streams[id].enable = true;
            callback(null, stream);
          });
        });
      } else {
        that.setting_up_video_receive = true;

        const videoRecvClient = new WebrtcClient({
          mjwt: that.mjwt,
          server: that.urls.ucs,
          email: that.email,
          site: that.name,
          type: "video",
          meetingID: that.meetingID,
          mode: "recvonly",
          ucg: false,
          log: that.log,
          completed_callback() {
            that.video_recv_client = videoRecvClient;
            that.setting_up_video_receive = false;

            videoRecvClient.turnOn(id, (stream) => {
              $(document).trigger("onVideoRecvClient");

              that.remote_video_streams[id].enable = true;

              callback(null, stream);
            });
          },
          error_callback: () => {
            that.setting_up_video_receive = false;
          },
        });
      }
    } else {
      that.video_recv_client.turnOn(id, (stream) => {
        that.remote_video_streams[id].enable = true;

        callback(null, stream);
      });
    }
  };

  that.enableAudio = function (callback = () => {}) {
    that.audio_recv_client = new WebrtcClient({
      mjwt: that.mjwt,
      email: that.email,
      site: that.name,
      server: that.urls.ucs,
      type: "audio",
      meetingID: that.meetingID,
      mode: "recvonly",
      ucg: false,
      log: that.log,
      completed_callback: () => {
        that.audio_send_client = new WebrtcClient({
          mjwt: that.mjwt,
          email: that.email,
          site: that.name,
          server: that.urls.ucs,
          type: "audio",
          meetingID: that.meetingID,
          mode: "sendonly",
          ucg: false,
          log: that.log,
          completed_callback: () => {
            callback();
          },
          error_callback: (err) => {
            callback(err);
          },
        });
      },
      error_callback: (err) => {
        callback(err);
      },
    });
  };

  that.disableRemoteVideo = async (id) => {
    that.remote_video_streams[id].enable = false;
    that.videoStreamRemoved(id);
  };

  that.muteAudioInput = function () {
    const tracks = that.audio_send_client?.stream?.getAudioTracks();
    tracks ? (tracks[0].enabled = false) : null;
  };

  that.unMuteAudioInput = function () {
    const tracks = that.audio_send_client?.stream?.getAudioTracks();
    tracks ? (tracks[0].enabled = true) : null;
  };

  that.audioOutputVolume = (vol) => {
    document.querySelectorAll("[webrtctype=audio_recvonly]")[0].volume = vol;
  };

  that.muteAudioOutput = () => {
    document.querySelectorAll("[webrtctype=audio_recvonly]")[0].muted = true;
  };

  that.unMuteAudioOutput = () => {
    document.querySelectorAll("[webrtctype=audio_recvonly]")[0].muted = false;
  };

  that.disconnect = async () => {
    // stop audio send
    if (that.audio_send_client) {
      await that.audio_send_client.stop();
      delete that.audio_send_client;
    }

    // stop audio receive
    if (that.audio_recv_client) {
      await that.audio_recv_client.stop();
      delete that.audio_recv_client;
    }

    try {
      // stop video send
      that.disableLocalVideo();

      // stop video receive
      const promises = Object.values(that.remote_video_streams).map((s) =>
        that.disableRemoteVideo(s.id)
      );

      await Promise.all(promises);
      that.remote_video_streams = {};
    } catch (e) {
      that.log("error disabling a video stream");
    }

    // stop notification clients
    await that.audio_notification_client.stop();
    delete window.audio_webrtc_notification_client;

    await that.video_notification_client.stop();
    delete window.video_webrtc_notification_client;

    delete that.video_recv_client;
    that.video_send_clients = {};
  };

  that.auth = async function () {
    if (that.meetingID) {
      const url = that.jwt
        ? `https://${that.urls.api}/api/meeting/${that.meetingID}/join`
        : `https://${that.urls.api}/api/meeting/${that.meetingID}/join/guest`;
      const headers = that.jwt ? { Authorization: that.jwt } : {};
      return await fetch(url, { method: "POST", headers }).then((res) =>
        res.json()
      );
    } else {
      throw "Must specify a meeting ID";
    }
  };

  // Start it off
  try {
    that.auth().then((mjwt) => {
      that.mjwt = mjwt;
      that.log("authSuccess");
      that.callback(null);
    });
  } catch (err) {
    that.log(err);
    that.callback(err);
  }
}

// window.VisiWebRTC = VisiWebRTC;

export default VisiWebRTC;
