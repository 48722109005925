import VisiWebRTC from "av-sdk/visiwebrtc_api";
import { getHostname } from "@utilities";
import { MeetingStore } from "@state/types";

export default async ({
  uuid,
  email,
  jwt,
  name,
  meetingStore,
  stream,
}: {
  uuid: string;
  email: string;
  jwt?: string;
  name: string;
  stream?: MediaStream;
  meetingStore: MeetingStore;
}) => {
  console.log("joining");
  try {
    const visiClient = new VisiWebRTC({
      server: getHostname(),
      email,
      jwt,
      meetingID: uuid,
      name,
      // log: console.log,
      callback: (err: Error) => {
        if (err) {
          console.log("Error occurs in new visiClient", err);
          return;
        }

        visiClient.connectToMeeting({
          meetingID: uuid,
          camera:
            meetingStore.activeVideoStream === null
              ? stream
              : meetingStore.activeVideoStream,
          videoStreamAdded: (
            id: number,
            email: string,
            name: string,
            camera: MediaStream,
            codecName: string,
            isScreenShare: boolean
          ) => {
            onVideoStreamAdded(
              id,
              email,
              name,
              camera,
              visiClient,
              meetingStore,
              codecName,
              isScreenShare
            );
          },
          audioStreamAdded: (id: string, email: string, name: string) => {
            console.log("audioStreamAdded", id, email, name);
            meetingStore.addRemoteAudioClient({ id, email, name });
          },
          audioStreamRemoved: (id: string) => {
            console.log("audioStreamRemoved", id);
            meetingStore.removeRemoteAudioClientById(id);
          },
          videoStreamRemoved: (id: string) => {
            onVideoStreamRemoved(id, meetingStore, email);
          },
          callback: (err: Error) => {
            visiClient.enableAudio(() => {
              // console.log(
              //   "this is the client",
              //   client?.audio_send_client?.audioStream
              // );
              // setAudioStream(client?.audio_send_client?.audioStream);
            }); // enable all audio
            if (meetingStore.videoIsOn) {
              enableLocalVideo(visiClient, meetingStore);
            } else {
              null;
            }

            if (err) {
              // TODO connect error
              console.log("Error occurs in connectMeeting", err);
              alert(err);
              return err;
            }
          },
        });
      },
    });

    meetingStore.setIsInMeeting(true);
    meetingStore.setClient(visiClient);
  } catch (e) {
    alert("There is an error connecting to the meeting");
    meetingStore.setIsInMeeting(false);
    console.log("this is the error and there is no visiClient", e);
  }
};

const onVideoStreamAdded = (
  id: number,
  email: string,
  name: string,
  camera: MediaStream,
  client: any,
  meetingStore: MeetingStore,
  codecName: string,
  isScreenShare: boolean
) => {
  console.log("videoStreamAdded", id, email, name, camera, isScreenShare);
  client.enableRemoteVideo(
    id,
    (err: Error, stream: MediaStream, isScreenShare: boolean) => {
      console.log("enableRemoteVideo callback", id, err, stream, isScreenShare);
      if (err) {
        console.log(err);
        return;
      }

      if (codecName === "" || codecName === "Screen Share") {
        meetingStore.addScreenShareClient({
          id,
          email,
          name,
          camera,
          codecName,
          isScreenShare,
          stream,
        });
      } else {
        meetingStore.addRemoteClient({
          id,
          email,
          name,
          camera,
          stream,
          codecName,
          isScreenShare,
        });
      }
    }
  );
};

const onVideoStreamRemoved = (
  id: string,
  meetingStore: MeetingStore,
  email: string
) => {
  console.log("onVideoStreamRemoved", id);
  meetingStore.removeRemoteClientById(id);
  meetingStore.removeScreenShareClientById(id);
};

const enableLocalVideo = (client: VisiWebRTC, meetingStore: MeetingStore) => {
  client.enableLocalVideo(
    (stream: MediaStream) => {
      meetingStore.addLocalClient({
        deviceId: meetingStore.selectedVideoStream,
        label: meetingStore.videoDevices[0].label,
        id: meetingStore.selectedVideoStream,
        stream:
          meetingStore.activeVideoStream === null
            ? stream
            : meetingStore.activeVideoStream,
        email: `${client.email}`,
        name: `${client.name}`,
      });
    },
    null,
    null,
    meetingStore.selectedVideoStream
  );
};
